<template>
	<div :class="themeClass" class="width-fill bg-F8F9FE">
		<div class="underReviewBg-img flex-row-center-center">
			<div class="underReview-box bg-fff radius10 flex-row-start-center padding-20">
				<div class="flex-row-start-center">
					<div class="underReview1-img margin-r-35"></div>
					<div class="flex-colum">
						<div class="flex-colum margin-b-40">
							<div class="margin-b-15 font-size20 font-weight700 font-color-3FD09E">派车成功！</div>
							<div class="font-size13 font-color-666">您可以选择继续派车或前往派车详情查看！</div>
						</div>
						<div class="flex-row">
							<div class="underReview-btn text-center border-color-theme color-theme margin-r-10 pointer" @click="continueSendCar">
								继续派车
							</div>
							<div class="underReview-btn text-center border-color-theme color-theme pointer" @click="toOrderSendCarDetail">
								查看提货码
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="delivery-code-box flex-colum-space-between">
				<!--  v-show="isCreateQRCode" -->
				<div class="delivery-code-item flex-colum-space-between">
					<div class="font-size16 font-weight700" style="position: relative;top: 10px;">提货密码：{{ qrCoderContext }}</div>
					<!-- <div :id="'qrCodeDivID'+index" :ref="'qrCodeDiv'+index" class="QR-code"></div>
					<div class="font-color-666 font-size13">
						提货信息：
						<div ref="copyDiv" class="QRcode-text">{{ qrCoderText }}</div>
					</div> -->
					<img :src="imageUrl" />
				</div>
				<div @click="copyInfo" class="copy-btn flex-row-center-center pointer">
					复制提货信息
				</div>
				<!-- <el-button style="width: 202px;margin-top: 10px;" plain :loading="true" size="medium">下载图片</el-button> -->
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import SERVER from '../../../config/config.json'
	export default {
		data() {
			return {
				dialogVisible: false,
				qrCoderContext: '',
				qrCoderText: '',
				imageUrl: ''
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			handleClose() {
				this.dialogVisible = false;
			},
			//继续派车
			continueSendCar() {
				this.$router.push({
					name: 'DispatchOrder',
					query: {}
				})
			},
			//跳转派车详情
			toOrderSendCarDetail() {
				if (this.$route.query.obj) {
					let obj = JSON.parse(this.$route.query.obj);
					if (JSON.stringify(obj) != '{}') {
						this.popoverShow(obj);
					} else {
						this.$router.push({
							name: 'AlreadySendCar',
							query: {
								obj: this.$route.query.obj
							}
						})
					}
				}
			},
			// 提货码显示
			popoverShow(item) {
				console.log('item', item);
				let url = SERVER.Environment == 'test' ? SERVER.API_SERVER : window.API_SERVER;
				this.imageUrl = url + 'api/mall/ebsale/deliver-order/get-d-code-png/' + item.fDeliveryOrderID +
					'.png'
				this.getQRCodeByDeliveryList(item);
			},
			// 根据派车单ID 获取提货码信息
			getQRCodeByDeliveryList(item) {
				this.ApiRequestPostNOMess('api/mall/ebsale/deliver-order/get-qr-code-text', {
					id: item.fDeliveryOrderID
				}).then(res => {
					this.qrCoderContext = res.obj.password
					// this.qrCoderText= res.obj.text

					this.qrCoderText = res.obj.text.replace(/<br>/gi, ""); //清除"数字"和"."以外的字符
					this.dialogVisible = true;
					// this.createQrCode(listIndex, res.obj.password)
				}, error => {})
			},
			// 复制提货信息
			copyInfo(listIndex) {
				let refCopyInfo = this.qrCoderText;
				var input = document.createElement("input"); // js创建一个input输入框
				input.value = refCopyInfo; // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
				document.body.appendChild(input); // 将输入框暂时创建到实例里面
				input.select(); // 选中输入框中的内容
				document.execCommand("Copy"); // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				this.tipsInfo("复制提货信息成功")
			},
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.underReviewBg-img {
		width: 100%;
		height: 550px;
		// background-image: url(../../../assets/imgs/HomePage/OpenShopUnderReviewBg.png);
		background-size: 100% 100%;
	}

	.underReview-box {
		width: 860px;
		height: 213px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	}

	.underReview1-img {
		width: 214px;
		height: 214px;
		background-image: url(https://www.henghesoft.com:9531/static/image/Business/SendCarsSuccess.png);
		background-size: 100% 100%;
	}

	.underReview-btn {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}
	
	.copy-btn {
		width: 200px;
		height: 34px;
		border: 1px solid #0173FE;
		color: #0173FE;
	}
</style>
